@font-face {
    font-family: 'Flecha';
    src: url('/fonts/Flecha/FlechaL-Bold.woff2') format('woff2'),
      url('/fonts/Flecha/FlechaL-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Flecha';
    src: url('/fonts/Flecha/FlechaL-BoldItalic.woff2') format('woff2'),
      url('/fonts/Flecha/FlechaL-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Flecha';
    src: url('/fonts/Flecha/FlechaL-Regular.woff2') format('woff2'),
      url('/fonts/Flecha/FlechaL-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Flecha';
    src: url('/fonts/Flecha/FlechaL-LightItalic.woff2') format('woff2'),
      url('/fonts/Flecha/FlechaL-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }